import { Text, TextLink } from "@kablamooss/geo-ds-core-components";
import styled from "styled-components";
import RootLayout from "../components/layout/RootLayout/RootLayout";
import type { NextPageWithLayout } from "./_app";

const StyledNotFoundPageLayout = styled.div`
  display: grid;
  gap: 3rem;
  margin: auto;
`;

const StyledDescription = styled.div`
  display: grid;
  gap: 0.75rem;
  text-align: center;
`;

const StyledTextLink = styled(TextLink)`
  margin: auto;
`;

const NotFoundPage: NextPageWithLayout = () => {
  return (
    <StyledNotFoundPageLayout>
      <StyledDescription>
        <Text size="h1">We can&apos;t find this page</Text>
        <Text>Looks like you&apos;ve taken a wrong turn</Text>
      </StyledDescription>
      <StyledTextLink href="/">Return home</StyledTextLink>
    </StyledNotFoundPageLayout>
  );
};

NotFoundPage.getLayout = (page) => {
  return <RootLayout>{page}</RootLayout>;
};

export default NotFoundPage;
